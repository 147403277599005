/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
const moodys = [
  'WR',
  'C',
  'Ca',
  'Caa3',
  'Caa2',
  'Caa1',
  'B3',
  'B2',
  'B1',
  'Ba3',
  'Ba2',
  'Ba1',
  'Baa3',
  'Baa2',
  'Baa1',
  'A3',
  'A2',
  'A1',
  'Aa3',
  'Aa2',
  'Aa1',
  'Aa',
  'Aaa',
];

const sAndP = [
  'D',
  'C',
  'CC',
  'CCC-',
  'CCC',
  'CCC+',
  'B-',
  'B',
  'B+',
  'BB-',
  'BB',
  'BB+',
  'BBB-',
  'BBB',
  'BBB+',
  'A-',
  'A',
  'A+',
  'AA-',
  'AA',
  'AA+',
  'AAA',
];

const numericRatingScale = {
  AAA: 17,
  'AA+': 16,
  AA: 15,
  'AA-': 14,
  'A+': 13,
  A: 12,
  'A-': 11,
  'BBB+': 10,
  BBB: 9,
  'BBB-': 8,
  'BB+': 7,
  BB: 6,
  'BB-': 5,
  'B+': 4,
  B: 3,
  'B-': 2,
  'CCC+': 1,
  CCC: 1,
  'CCC-': 1,
  CC: 1,
  C: 1,
  D: 1,
  Aaa: 17,
  Aa1: 16,
  Aa2: 15,
  Aa3: 14,
  A1: 13,
  A2: 12,
  A3: 11,
  Baa1: 10,
  Baa2: 9,
  Baa3: 8,
  Ba1: 7,
  Ba2: 6,
  Ba3: 5,
  B1: 4,
  B2: 3,
  B3: 2,
  Caa1: 1,
  Caa2: 1,
  Caa3: 1,
  Ca: 1,
};

const queriesInfo = {
  riskFreeRate: {
    method: 'bondYields',
    attribut: 'yield',
  },
  corporateTaxRate: {
    method: 'taxRates',
    attribut: 'taxRate',
  },
  mrp: {
    method: 'ratings',
    attribut: 'mrp',
  },
  rating: {
    method: 'ratings',
    attribut: 'rating',
  },
};

const metrics = ['riskFreeRate', 'corporateTaxRate', 'mrp', 'rating'];

const defaultColorScale = ['#8aba45', '#33491d'];
const url = '/graphql';
const credentials = 'Basic dXNlcjpzZWNyZXQ=';

const initI18n = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const locale = urlParams.get('locale') || 'en';
  I18n.fallbacks = 'defaultLocale';
  window.I18n.locale = locale;
  document.documentElement.lang = locale;

  // static replacements
  document.querySelectorAll('[data-i18n]').forEach(node => {
    const key = node.dataset.i18n;
    // eslint-disable-next-line no-param-reassign
    node.textContent = I18n.t(key);
  });
};

initI18n();
